import { defineStore } from 'pinia'
import { getToken, getUserInfo } from '@/utils'

interface AuthState {
    /** 用户信息 */
    userInfo: Auth.UserInfo
    /** 用户token */
    token: string
    /** 登录的加载状态 */
    loginLoading: boolean
}

export const useAuthStore = defineStore('auth-store', {
    state: (): AuthState => ({
        userInfo: getUserInfo(),
        token: getToken(),
        loginLoading: false,
    }),
    getters: {
        /** 是否登录 */
        isLogin(state) {
            // return Boolean(state.token)
            return true
        },
    },
    actions: {},
})
