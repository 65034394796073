import type { Router } from 'vue-router'
import { defineStore } from 'pinia'
import { ElMessage } from 'element-plus'
import { transformAuthRoutesToVueRoutes, transformAuthRoutesToMenuRoutes, getCacheRoutes, getUserInfo, getCookie } from '@/utils'
// import { fetchUserRoutes } from '@/service/api'
import { auth } from '@/service/api'
import asyncRoute from '@/router/async'
import { useTabStore } from './tab'

interface RouteState {
    /** 是否添加过动态路由 */
    isAddedDynamicRoute: boolean
    /** 路由首页name */
    routeHomeName: string
    /** 菜单 */
    menus: Menu.GlobalMenuOption[]
    /** 缓存的路由名称 */
    cacheRoutes: string[]
}

export const useRouteStore = defineStore('route-store', {
    state: (): RouteState => ({
        isAddedDynamicRoute: false,
        routeHomeName: 'home',
        menus: [],
        cacheRoutes: [],
    }),
    actions: {
        /**
         * 初始化动态路由
         * @param router - 路由实例
         */
        async initDynamicRoute(router: Router) {
            const { initHomeTab } = useTabStore()

            let routes: any = asyncRoute
            let routerList: Array<any> = []
            // if (home) {
            //     this.routeHomeName = home
            //     const vueRoutes = transformAuthRoutesToVueRoutes(routes)
            //     vueRoutes.forEach((route) => {
            //         router.addRoute(route)
            //     })
            //     this.cacheRoutes = getCacheRoutes(routes)
            //     this.menus = transformAuthRoutesToMenuRoutes(routes, router.getRoutes())
            //
            //     initHomeTab(home, router)
            //     this.isAddedDynamicRoute = true
            // }
            if (getCookie('HrmApiCookie')) {
                const { code, info, message } = await auth({})
                if (code !== 200) {
                    routes = []
                    ElMessage(info)
                } else {
                    const { Menu, App, Button } = info
                    if (Menu === null) {
                        routes = []
                    } else {
                        routes.forEach((item: any) => {
                            let obj = {
                                children: [],
                            }
                            if (item.children.length !== 0) {
                                item.children.forEach((row: any) => {
                                    if (Menu && Menu.filter((MList: any) => MList.parentid !== 0).find((list: any) => list.Menu_url === `${item.path}/${row.path}`)) {
                                        obj.children.push(row)
                                    }
                                })
                            }
                            // if(Menu&&Menu.find((list:any) => list.Menu_name === item.meta.title)){
                            //     routerList.push(item)
                            // }
                            if (obj.children.length !== 0) {
                                delete item.children
                                obj = Object.assign({}, item, obj)
                                routerList.push(obj)
                            }
                        })
                        routes = routerList
                        if (Button == null) return
                        localStorage.setItem('userButton', JSON.stringify(Button))
                    }
                }
            }

            const home = 'Info'
            if (home) {
                this.routeHomeName = home
                const vueRoutes = transformAuthRoutesToVueRoutes(routes)
                vueRoutes.forEach((route) => {
                    router.addRoute(route)
                })
                this.cacheRoutes = getCacheRoutes(routes)
                this.menus = transformAuthRoutesToMenuRoutes(routes, router.getRoutes())

                initHomeTab(home, router)
                this.isAddedDynamicRoute = true
            }
        },
    },
})
