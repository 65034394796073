<template>
    <div class="fh-100 bg-white scroll">
        <router-view v-slot="{ Component, route }">
            <transition
                appear
                name="fade-bottom"
                mode="out-in"
            >
                <keep-alive :include="routeStore.cacheRoutes">
                    <component
                        v-if="app.reloadFlag"
                        :is="Component"
                        :key="route.path"
                    ></component>
                </keep-alive>
            </transition>
        </router-view>
    </div>
</template>

<script setup lang="ts">
import { useRouteStore, useAppStore } from '@/store'
const routeStore = useRouteStore()
const app = useAppStore()
</script>

<style scoped lang="less">
.content-wrap {
    height: 100%;
    background-color: #fff;
}
</style>
