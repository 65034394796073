import type { App } from 'vue'
import { createPinia } from 'pinia'
// import { usePublic } from "@/store/modules/public";

const initialStoreData = () => {
    // const publicStore = usePublic()
    // publicStore.initData()
}

export function setupStore(app: App) {
    const store = createPinia()
    app.use(store)
    initialStoreData()
}

export * from './modules'
