<template>
    <div
        class="slide-menu-wrap animation fh-100 relative flex column a-flex-start j-flex-start"
        :style="{
			'--menu-width': !menuStore.isCollapse ? '64px' : '240px',
			'--background-color': backgroundColor,
			'--border-color': borderColor,
			'--text-color': textColor,
			'--hover-color': hoverColor,
			'--active-text-color': activeTextColor,
		}"
        :class="[{hidden: menuStore.isCollapse}]"
    >
        <div
            v-for="items in menus"
            :key="items.name"
            :class="['slide-menu', {active: menuStore.current === items.name && menuStore.isCollapse}]"
        >
            <div
                class="w-64 h-64 flex column center guide relative z-100"
                :class="{'icon-active':  menuStore.current === items.name}"
            >
                <cu-icon
                    class="icon cursor-pointer"
                    :icon="items.icon"
                    :color="textColor"
                    @click="menuStore.setCurrent(items.name)"
                ></cu-icon>
                <span>{{items.title}}</span>
            </div>
            <div
                class="slide-menu-item absolute z-99 column fh-100"
                style="width: 240px;"
            >
                <span class="slide-menu-sub h-64 pl-20 pr-10 flex a-center user-select-none">{{items.title}}</span>
                <SlideMenuSub :menus="items.children" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useMenuStore } from '@/store'
import { SlideMenuSub } from './index'

interface Props {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    activeTextColor?: string
    hoverColor?: string
    menus?: Menu.GlobalMenuOption[]
}
withDefaults(defineProps<Props>(), {
    backgroundColor: '#000',
    borderColor: '#202020',
    textColor: '#f1f1f1',
    activeTextColor: '#409eff',
    hoverColor: '#1a1a1a',
    menus() {
        return []
    },
})
const menuStore = useMenuStore()
</script>
<style scoped>
.slide-menu-wrap {
    position: relative;
    width: var(--menu-width);
    background-color: var(--background-color);
}
.slide-menu-wrap ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--border-color);
    z-index: 1;
}
.slide-menu-item {
    padding-left: 64px;
    top: 0;
    display: none;
    background-color: var(--background-color);
    border-right: 1px solid var(--border-color);
}
.slide-menu-item::after {
    content: '';
    position: absolute;
    left: 64px;
    width: 1px;
    background: var(--border-color);
    height: 100%;
}
.slide-menu-sub {
    flex-shrink: 0;
    font-size: 18px;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
}
.slide-menu:hover .icon {
    transform: scale(1.2);
}
.slide-menu:hover .slide-menu-item {
    display: flex;
}
.slide-menu.active .slide-menu-item {
    display: flex;
}
.icon-active {
    background-color: #e8f0ff;
}
.guide > span {
    font-size: 12px;
    color: var(--text-color);
}
.guide:hover {
    background-color: #e8f0ff;
}
</style>
