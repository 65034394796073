import resultTypeInterceptor from './result.type.interceptor'
import resultInterceptor from './result.interceptor'
import undefinedParamsHandlerInterceptor from './undefined-params-handler.interceptor'
import authorizationInterceptor from './authorization.interceptor'

export default [
    resultTypeInterceptor,
    resultInterceptor,
    undefinedParamsHandlerInterceptor,
    authorizationInterceptor,
]
