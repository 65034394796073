/**
 * 创建实例, 添加拦截
 */

import axios from 'axios'
import interceptorsRequest from './interceptors/request'
import interceptorsResponse from './interceptors/response'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: import.meta.env.VITE_BASE_URL,
    timeout: 10000, // 请求超时时间
})
interceptorsRequest.forEach((i: any) => {
    request.interceptors.request.use(...i)
})
interceptorsResponse.forEach((i: any) => {
    request.interceptors.response.use(...i)
})

export default request
