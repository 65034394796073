import type { App } from 'vue'
import ElementPlus from 'element-plus'
import '@/assets/style/layout.css'
import '@/assets/style/reset.css'
import '@/assets/style/transition.css'
import '@/assets/style/variables.less'
import 'element-plus/theme-chalk/src/message.scss'

/** 引入静态资源(全局引入css、字体等) */
export default function setupAssets(app: App) {
    app.use(ElementPlus, { zIndex: 3000 })
}
