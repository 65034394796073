import type { RouteLocationRaw } from 'vue-router'
import { router as globalRouter } from '@/router'

/**
 * 路由跳转
 * @param inSetup - 是否在vue页面/组件的setup里面调用，在axios里面无法使用useRouter和useRoute
 */
export function useRouter() {
    const router = globalRouter
    const route = globalRouter.currentRoute

    /**
     * 路由跳转
     * @param to - 需要跳转的路由
     * @param newTab - 是否在新的浏览器Tab标签打开
     */
    function open(to: RouteLocationRaw, outer: boolean = false) {
        if(!outer) {
			const routerData = router.resolve(to)
        	window.open(routerData.href, '_blank')
		} else {
			window.open(to as string, '_blank')
		}
    }

    /** 返回上一级路由 */
    function back() {
        router.go(-1)
    }

    return {
        router,
        route,
        open,
        back,
    }
}
