import { defineAsyncComponent } from 'vue'
import type { AsyncComponentLoader } from 'vue'

type SVGTYPE = {
    [key: string]: Function
}
const importSvg = import.meta.glob('../assets/svg/**/*.svg')
const SVG: SVGTYPE = {}
Object.keys(importSvg).forEach((item, k) => {
    const key = (item.match(/(?<=svg\/).*?(?=\.)/g)?.join('') as string) || `svg-${k}`
    SVG[key] = importSvg[item]
})

export function SvgIcon(name: string) {
    return defineAsyncComponent({
        loader: SVG[name] as AsyncComponentLoader,
    })
}
