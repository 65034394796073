import { defineAsyncComponent } from 'vue'
import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import authRoute from '@/views'
import { Admin, Content } from '@/layout'

type AuthRouteType = {
    [key: string]: () => Promise<Component>
}
const AuthRoutes: AuthRouteType = { ...authRoute }
/**
 * 将权限路由转换成vue路由
 * @param routes - 路由
 */
export function transformAuthRoutesToVueRoutes(routes: AuthRoute.Route[]) {
    const VueRoutes: RouteRecordRaw[] = []
    transformRoute(routes, VueRoutes)
    return VueRoutes
}
/** 接口数据转变路由数据 */
function transformRoute(route: AuthRoute.Route[], vueRoute: RouteRecordRaw[]) {
    route.forEach((item: AuthRoute.Route) => {
        const itemRoute = { ...item } as RouteRecordRaw
        if (item.component === 'Admin') {
            itemRoute.component = Admin
        } else if (isEmptyComponent(item.component as string)) {
            itemRoute.component = Content
        } else if (item.component) {
            Object.assign(itemRoute, {
                component: asyncLoadComponent(`${item.component}`),
            })
        }
        if (item.children) {
            itemRoute.children = []
            transformRoute(item.children, itemRoute.children)
        }
        vueRoute.push(itemRoute)
    })
}
/** 判断空组件 */
function isEmptyComponent(name: string) {
    const reg = /_component/gi
    return reg.test(name)
}
/** 异步加载组件 */
function asyncLoadComponent(name: string) {
    return () => setComponentName(AuthRoutes[`${name}`!], name)
}

/** 给页面组件设置名称 */
async function setComponentName(asyncComponent: () => Promise<Component>, name: string) {
    const component = (await asyncComponent()) as { default: Component }
    Object.assign(component.default, { name })
    return component
}

/**
 * 获取缓存的路由对应组件的名称
 * @param routes - 转换后的vue路由
 */
export function getCacheRoutes(routes: AuthRoute.Route[]) {
    const cacheNames: string[] = []
    routes.forEach((route) => {
        // 只需要获取二级路由的缓存的组件名
        if (route.children && route.children.length) {
            route.children!.forEach((item) => {
                if (isKeepAlive(item)) {
                    cacheNames.push(item.name as string)
                }
            })
        }
    })
    return cacheNames
}

/**
 * 路由是否缓存
 * @param route
 */
function isKeepAlive(route: AuthRoute.Route) {
    return Boolean(route?.meta?.keepAlive)
}

/**
 * @description 判断列表target中是否包含了列表2中的某一项
 * 因为用户权限 access 为数组，includes 方法无法直接得出结论
 * */
export function hasAccess(target: string[], list: string[]) {
    let status = false
    for (let item of list) {
        if (target.includes(item)) status = true
    }
    return status
}
/**
 * @description 接口路由整合成菜单路由
 * */
export function transformAuthRoutesToMenuRoutes(fetchRoutes: AuthRoute.Route[], matchRoutes: RouteRecordRaw[]) {
    const menuList: Menu.GlobalMenuOption[] = []
    function transformRoute(route: AuthRoute.Route[], vueRoute: Menu.GlobalMenuOption[]) {
        route.forEach((item: AuthRoute.Route) => {
            const itemRoute: Menu.GlobalMenuOption = {
                name: item.name,
                redirect: item.redirect,
                path: matchRoutes.find((i) => i.name === item.name)?.path as string,
                title: item.meta?.title,
                hide: item.meta?.hide,
                icon: item.meta?.icon as string,
            }
            if (item.children) {
                itemRoute.children = []
                transformRoute(item.children, itemRoute.children)
            }
            vueRoute.push(itemRoute)
        })
    }
    transformRoute(fetchRoutes, menuList)
    return menuList
}
