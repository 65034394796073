import type { Router, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useAuthStore, useRouteStore } from '@/store'
import { hasAccess } from '@/utils'
import { createDynamicRouteGuard } from './dynamic'

/** 处理路由页面的权限 */
export async function createPermissionGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, router: Router) {
    const permission = await createDynamicRouteGuard(to, from, next, router)
    if (!permission) return

    // 外链路由, 从新标签打开，返回上一个路由
    if (to.meta.href) {
        window.open(to.meta.href)
        next({ path: from.fullPath, replace: true, query: from.query })
        return
    }
    const auth = useAuthStore()
    const routeStore = useRouteStore()
    const permissions = to.meta.permissions || []
    const needLogin = !!to.meta?.needLogin
    const hasPermission = !permissions.length || hasAccess(permissions, auth.userInfo.permissions)

    const actions: Common.StrategyAction[] = [
        // 已登录状态跳转登录页，跳转至首页
        [
            auth.isLogin && to.path === '/login',
            () => {
                if (to?.query?.redirect) {
                    const path = to.query.redirect as string
                    next({ path })
                } else {
                    next({ name: routeStore.routeHomeName })
                }
            },
        ],
        // 不需要登录权限的页面直接通行
        [
            !needLogin,
            () => {
                next()
            },
        ],
        // 未登录状态进入需要登录权限的页面
        [
            !auth.isLogin && needLogin,
            () => {
                const redirect = to.fullPath
                next({ path: '/', query: { redirect } })
            },
        ],
        // 登录状态进入需要登录权限的页面，有权限直接通行
        [
            auth.isLogin && needLogin && hasPermission,
            () => {
                next()
            },
        ],
        // 登录状态进入需要登录权限的页面，无权限，重定向到无权限页面
        [
            auth.isLogin && needLogin && !hasPermission,
            () => {
                next({ path: '/403' })
            },
        ],
    ]
    actions.some((item, index) => {
        const [flag, action] = item
        if (flag) {
            action()
        }
        return flag
    })
}
