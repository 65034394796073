import { createApp } from 'vue'
import { setupDirectives } from '@/directives'
import { setupAssets, setupVMdEditor } from '@/plugins'
import { setupRouter } from '@/router'
import { setupStore } from '@/store'
import App from './App.vue'
import 'element-plus/theme-chalk/src/message-box.scss'
import 'element-plus/theme-chalk/src/message.scss'

async function setupApp() {
    const app = createApp(App)
    // 引入静态资源
    setupAssets(app)
	// 引入Markdown编辑器
    setupVMdEditor(app)
    // 挂载pinia状态
    setupStore(app)
    // 挂载自定义vue指令
    setupDirectives(app)
    // 挂载路由
    await setupRouter(app)
    // 路由准备就绪后挂载 App
    app.mount('#app')
}

setupApp()
