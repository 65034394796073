/**
 * 拦截器：给 request Header 统一增加 Header
 *
 * header : {
 *     Authorization: token
 * }
 *
 * @type {Array}
 */
import { getToken } from '@/utils'

const replaceStr = (str: string) => str && str.replace(/(\s+|Bearer)/gi, '')

const authorizationInterceptor = [
    (config: any) => {
        const token = getToken()
        if (token) {
            config.headers['Accept-Token'] = replaceStr(token)
        }
        return config
    },
    (error: any) => {
        throw error
    },
]

export default authorizationInterceptor
