import type { App } from 'vue'
import { useTitle } from '@vueuse/core'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { transformAuthRoutesToVueRoutes, createPermissionGuard } from '@/utils'
import Routes from './routes'

export const router = createRouter({
    history: createWebHashHistory(),
    routes: transformAuthRoutesToVueRoutes(Routes),
})

router.beforeEach(async (to, from, next) => {
    // 页面跳转权限处理
    await createPermissionGuard(to, from, next, router)
})
router.afterEach((to) => {
    // 设置document title
    useTitle(to.meta.title)
})

export async function setupRouter(app: App) {
    app.use(router)
    await router.isReady()
}

