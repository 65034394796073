<template>
    <el-config-provider :locale="locale">
        <RouterView />
    </el-config-provider>

</template>
<script>
import { defineComponent } from 'vue'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
    components: {
        ElConfigProvider,
    },
    setup() {
        return {
            locale: zhCn,
        }
    },
})
</script>
<style lang='scss'>
.img {
  display: flex;
  flex-wrap: wrap;

  & > a {
    width: 30px;
    height: 30px;
    margin: 3px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.avatar-uploader{

  .el-upload{
    width: 100px;
    height: 100px;
    &>img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

}
</style>
