import type { App, Directive } from 'vue'

/**
 * @description 鉴权指令
 * 当传入的权限当前用户没有时，会移除该组件
 * 用例：<Tag v-auth="['admin']">text</Tag>
 * */
import { getUserInfo, hasAccess } from '@/utils'

export default function setupAuthDirective(app: App) {
    const authDirective: Directive<HTMLElement, Auth.PermissionsType[]> = {
        mounted(el: HTMLElement, binding) {
            const { value } = binding
            const { permissions = [] } = getUserInfo()
            if (value && value.length && permissions && permissions.length) {
                const isPermission = hasAccess(value, permissions)
                if (!isPermission) {
                    el.parentNode && el.parentNode.removeChild(el)
                }
            }
        },
    }

    app.directive('auth', authDirective)
}
