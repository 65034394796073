import { defineStore } from 'pinia'
import { useRouter, firstUpperCase } from '@/utils'

interface MenuState {
    /** 是否展开菜单栏 */
    isCollapse: boolean
    /** 菜单栏类型 */
    menuType: string
    /** 当前激活路由name */
    current: string
}

export const useMenuStore = defineStore('menu-store', {
    state: (): MenuState => ({
        isCollapse: false,
        menuType: 'SlideMenu',

        current: '',
    }),
    actions: {
        // 设置菜单栏折叠
        setCollapse() {
            this.isCollapse = !this.isCollapse
            const root = document.querySelector(':root') as HTMLElement
            root.setAttribute('style', `--root-menu-width: ${!this.isCollapse ? '64px' : '200px'}`)
            if (this.isCollapse) {
                const { route } = useRouter()
                const name = route.value.path
                    .split('/')
                    .filter((x) => x)
                    .slice(0, 1)
                    .join(',')
                this.setCurrent(firstUpperCase(name))
            } else {
                this.setCurrent('')
            }
        },
        // 设置菜单栏折叠
        setCurrent(name: string) {
            this.current = name
        },
        // 设置菜单栏模式
        setMenuType(name: string) {
            this.menuType = name
        },
    },
})
