import type { RouteRecordNormalized, RouteLocationNormalizedLoaded } from 'vue-router'
import { EnumStorageKey } from '@/types/enum/storage'
import { LocalStorage } from '@/utils'

/**
 *	根据vue路由获取tab路由
 * @param route
 */
export function getTabRouteByVueRoute(
    route: RouteRecordNormalized | RouteLocationNormalizedLoaded
) {
    const tabRoute: Menu.GlobalTabRoute = {
        name: route.name,
        path: route.path,
        meta: route.meta,
        scrollPosition: {
            left: 0,
            top: 0,
        },
    }
    return tabRoute
}

/** 缓存多页签数据 */
export function setTabRoutes(data: Menu.GlobalTabRoute[]) {
    LocalStorage.setExpire(EnumStorageKey['tab-routes'], data)
}

/** 获取缓存的多页签数据 */
export function getTabRoutes() {
    const routes: Menu.GlobalTabRoute[] = []
    const data = LocalStorage.getExpire(EnumStorageKey['tab-routes']) as Menu.GlobalTabRoute[]
    if (data) {
        const defaultTabRoutes = data.map((item) => ({
            ...item,
            scrollPosition: {
                left: 0,
                top: 0,
            },
        }))
        routes.push(...defaultTabRoutes)
    }
    return routes
}

/** 清空多页签数据 */
export function clearTabRoutes() {
    setTabRoutes([])
}

/**
 * 获取该页签在多页签数据中的索引
 * @param tabs - 多页签数据
 * @param path - 该页签的路径
 */
export function getIndexInTabRoutes(tabs: Menu.GlobalTabRoute[], path: string) {
    return tabs.findIndex((tab) => tab.path === path)
}

/**
 * 判断该页签是否在多页签数据中
 * @param tabs - 多页签数据
 * @param path - 该页签的路径
 */
export function isInTabRoutes(tabs: Menu.GlobalTabRoute[], path: string) {
    return getIndexInTabRoutes(tabs, path) > -1
}
