<template>
    <div class="fh-100 bg-default">
        <div v-if="getCookie('HrmApiCookie')">
          <Content />
        </div>
        <div class="fh-100 flex" v-else>
            <component
                :is="active"
                backgroundColor="#fff"
                borderColor="#f1f1f1"
                textColor="#000"
                activeTextColor="#409eff"
                hoverColor="#f1f1f1"
                :menus="menus"
            />
            <div class="right-content flex column">
                <header class="flex h-50 p-10 a-center j-space-between bg-white">
                    <div class="flex a-center">
                        <el-icon
                            class="mr-20 cursor-pointer"
                            @click="handleCollapse"
                        >
                            <fold v-if="!menuStore.isCollapse" />
                            <expand v-if="menuStore.isCollapse" />
                        </el-icon>
                        <BreadCrumb />
                    </div>
                    <el-dropdown
                        @command="handleCommand"
                        trigger="click"
                        size="default"
                    >
                        <span class="el-dropdown-link cursor-pointer">
                            {{userInfo.userName}}
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="Menu">折叠菜单模式</el-dropdown-item>
                                <el-dropdown-item command="SlideMenu">平展菜单模式</el-dropdown-item>
                                <el-dropdown-item command="quit">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </header>
                <TagNavigation />
                <section class="flex-1 p-10 scroll">
                  <Content />
                </section>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Component } from 'vue'
import { Fold, Expand, ArrowDown } from '@element-plus/icons-vue'
import { useMenuStore, useAuthStore, useRouteStore } from '@/store'
import { clearAuthStorage } from '@/utils'
import { BreadCrumb, Menu, SlideMenu, TagNavigation } from './components'
import { Content } from '../index'
import {getCookie} from '@/utils'

const { menus } = useRouteStore()
const menuStore = useMenuStore()
const { userInfo } = useAuthStore()
const exceptions: Record<'Menu' | 'SlideMenu' | any, Component> = {
    Menu,
    SlideMenu,
}
const active = computed(() => exceptions[menuStore.menuType])
function handleCollapse() {
    menuStore.setCollapse()
}
function handleCommand(command: string) {
    switch (command) {
        case 'Menu':
            menuStore.setMenuType('Menu')
            break
        case 'SlideMenu':
            menuStore.setMenuType('SlideMenu')
            break
        case 'quit':
            clearAuthStorage()
            location.reload()
            break
    }
}
</script>

<style scoped>
.right-content {
    width: calc(100% - var(--root-menu-width));
}
</style>