import { nextTick } from 'vue'
import { defineStore } from 'pinia'

interface AppState {
 
    reloadFlag: boolean
}

export const useAppStore = defineStore('app-store', {
    state: (): AppState => ({
        reloadFlag: true,
    }),
    actions: {
        /**
         * 重载页面
         * @param duration - 重载的延迟时间(ms)
         */
        async reloadPage(duration = 0) {
            this.reloadFlag = false
            await nextTick()
            if (duration) {
                setTimeout(() => {
                    this.reloadFlag = true
                }, duration)
            } else {
                this.reloadFlag = true
            }
            setTimeout(() => {
                document.documentElement.scrollTo({ left: 0, top: 0 })
            }, 100)
        },
    },
})
