export default [
    {
        path: '/dishes',
        redirect: '/dishes/info',
        name: 'Dishes',
        component: 'Admin',
        meta: {
            title: '菜品管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'Info',
                name: 'Info',
                component: 'Info',
                meta: {
                    title: '菜品资料',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'InfoRelease',
                name: 'InfoRelease',
                component: 'InfoRelease',
                meta: {
                    title: '菜品发布',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'ReleaseAuto',
                name: 'ReleaseAuto',
                component: 'ReleaseAuto',
                meta: {
                    title: '菜品自动发布',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'ServedDishes',
                name: 'ServedDishes',
                component: 'ServedDishes',
                meta: {
                    title: '招待菜品',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/supermarket',
        redirect: '/supermarket/list',
        name: 'Supermarket',
        component: 'Admin',
        meta: {
            title: '超市管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'list',
                name: 'SList',
                component: 'SList',
                meta: {
                    title: '超市列表',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'classify',
                name: 'Classify',
                component: 'Classify',
                meta: {
                    title: '超市分类',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/order',
        redirect: '/order/list',
        name: 'Order',
        component: 'Admin',
        meta: {
            title: '订单管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'list',
                name: 'OList',
                component: 'OList',
                meta: {
                    title: '订单列表',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/appraise',
        redirect: '/appraise/lunch',
        name: 'Appraise',
        component: 'Admin',
        meta: {
            title: '评价管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'lunch',
                name: 'Lunch',
                component: 'Lunch',
                meta: {
                    title: '午餐评价',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'shopping',
                name: 'Shopping',
                component: 'Shopping',
                meta: {
                    title: '购物评价',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'feedBack',
                name: 'feedBack',
                component: 'feedBack',
                meta: {
                    title: '晚餐评价',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'hostMeal',
                name: 'hostMeal',
                component: 'hostMeal',
                meta: {
                    title: '招待餐评价',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/business',
        redirect: '/business/list',
        name: 'Business',
        component: 'Admin',
        meta: {
            title: '交易管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'list',
                name: 'BList',
                component: 'BList',
                meta: {
                    title: '交易列表',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'log',
                name: 'Log',
                component: 'Log',
                meta: {
                    title: '交易记录',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'detailBill',
                name: 'detailBill',
                component: 'detailBill',
                meta: {
                    title: '明细账目',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'subsidy',
                name: 'Subsidy',
                component: 'Subsidy',
                meta: {
                    title: '补助列表',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
            {
                path: 'detailed',
                name: 'Detailed',
                component: 'Detailed',
                meta: {
                    title: '补助明细',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/notice',
        redirect: '/notice/list',
        name: 'Notice',
        component: 'Admin',
        meta: {
            title: '公告管理',
            icon: 'icon-wendang',
            needLogin: true,
        },
        children: [
            {
                path: 'list',
                name: 'NList',
                component: 'NList',
                meta: {
                    title: '公告列表',
                    icon: 'icon-wendang',
                    needLogin: true,
                },
            },
        ],
    },
    {
        path: '/forum',
        redirect: '/forum/list',
        name: 'Forum',
        component: 'Admin',
        meta: {
            title: '论坛管理',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'list',
                name: 'FList',
                component: 'FList',
                meta: {
                    title: '论坛列表',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
    {
        path: '/smallBank',
        redirect: '/smallBank/list',
        name: 'SmallBank',
        component: 'Admin',
        meta: {
            title: '小仓库',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'articlesClass',
                name: 'Class',
                component: 'Class',
                meta: {
                    title: '用品类别',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'articlesList',
                name: 'List',
                component: 'List',
                meta: {
                    title: '用品列表',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'articlesApplyDetail',
                name: 'Sale1',
                component: 'Sale1',
                meta: {
                    title: '申请明细',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'articlesSaleDetail',
                name: 'Sale',
                component: 'Sale',
                meta: {
                    title: '售卖明细',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
    {
        path: '/primecost',
        redirect: '/primecost/list',
        name: 'primecost',
        component: 'Admin',
        meta: {
            title: '成本分析',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'cblist',
                name: 'cblist',
                component: 'cblist',
                meta: {
                    title: '分析表',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'analysis',
                name: 'analysis',
                component: 'analysis',
                meta: {
                    title: '采购成本分析',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'property',
                name: 'property',
                component: 'property',
                meta: {
                    title: '固定资产',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
    {
        path: '/dataAnalysis',
        redirect: '/dataAnalysis/datas',
        name: 'dataAnalysis',
        component: 'Admin',
        meta: {
            title: '数据分析',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'analysisData',
                name: 'datas',
                component: 'datas',
                meta: {
                    title: '招待餐数据',
                    icon: 'icon-wendang',
                },
            },
            {
                path: 'mealStatistics',
                name: 'statistic',
                component: 'statistic',
                meta: {
                    title: '用餐统计',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
    {
        path: '/partition',
        redirect: '/partition/list',
        name: 'partition',
        component: 'Admin',
        meta: {
            title: '餐厅分区',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'list',
                name: 'partitionList',
                component: 'index',
                meta: {
                    title: '餐厅分区',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
    {
        path: '/diningSituation',
        redirect: '/dining-situation/diningInfo',
        name: 'diningSituation',
        component: 'Admin',
        meta: {
            title: '用餐情况',
            icon: 'icon-wendang',
        },
        children: [
            {
                path: 'info',
                name: 'diningSituationInfo',
                component: 'diningInfo',
                meta: {
                    title: '用餐情况',
                    icon: 'icon-wendang',
                },
            },
        ],
    },
] as AuthRoute.Route[]
