<template>
    <div
        class="fw-100"
        v-for="item in list"
        :key="item.name"
    >
        <div
            v-if="!item.hide"
            class="flex column a-flex-start fw-100"
        >
            <div
                class="slide-menu-sub-item cursor-pointer fw-100 flex a-center j-space-between"
                :class="[{'bg-active': $route.name === item.name}]"
                @click="jumpTo(item)"
            >
                <span class="user-select-none">{{item.title}}</span>
                <el-icon
                    v-if="item.children"
                    class="cursor-pointer animation"
                    :class="[{rotate: item.show}]"
                    @click="item.show = !item.show"
                >
                    <arrow-up />
                </el-icon>
            </div>
            <SlideMenuSubChild
                v-if="showChild(item)"
                :menus="item.children"
            />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { ArrowUp } from '@element-plus/icons-vue'
import { useRouter, firstUpperCase } from '@/utils'
import { useMenuStore } from '@/store'
import { SlideMenuSub as SlideMenuSubChild } from './index'

type SubGlobalMenuOption = { show?: boolean } & Menu.GlobalMenuOption
interface Props {
    menus: SubGlobalMenuOption[]
}
const menuStore = useMenuStore()
const { router, route } = useRouter()
const props = defineProps<Props>()
const list = computed(() => {
    const openPath = route.value.path
        .split('/')
        .filter((x) => x)
        .map((x) => firstUpperCase(x))
    menuStore.setCurrent(openPath[0])
    props.menus.forEach((i) => {
        if (openPath.includes(i.name)) {
            i.show = true
        } else {
            i.show = false
        }
    })
    return props.menus
})
function jumpTo(item: SubGlobalMenuOption) {
    if (!item.children) {
        router.push(item.path)
    } else {
        item.show = !item.show
    }
}
function showChild(item: SubGlobalMenuOption) {
    return item.children && item.children.length > 0 && item.show
}
</script>
<style scoped>
.slide-menu-sub-item {
    font-size: 14px;
    color: var(--text-color);
    padding: 14px 20px;
    transition: background-color 0.6s;
}
.slide-menu-sub-item:hover {
    background-color: var(--hover-color);
}
.bg-active {
    background-color: var(--hover-color);
}
.bg-active > span {
    color: var(--active-text-color);
}
.rotate {
    transform: rotate(180deg);
}
</style>
