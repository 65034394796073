<template>
    <div
        ref="tabRef"
        class="fh-100 flex a-center a-flex-end"
    >
        <component
            :is="ChromeTab"
            v-for="item in tab.tabs"
            :key="item.name"
            :is-active="tab.activeTab === item.path"
            :closable="item.path !== tab.homeTab.path"
            @click="tab.handleClickTab(item.path)"
            @close="tab.removeTab(item.path)"
            @contextmenu="handleContextMenu($event, item.path)"
        >
            {{ item.meta.title }}
        </component>
    </div>
    <teleport to="body">
        <context-menu
            v-if="dropdown.visible"
            v-model:visible="dropdown.visible"
            :current-path="dropdown.currentPath"
            :x="dropdown.x"
            :y="dropdown.y"
        ></context-menu>
        <div
            v-if="dropdown.visible"
            class="mask"
            @click="hideDropdown"
            @contextmenu.prevent="hideDropdown"
        ></div>
    </teleport>
</template>

<script setup lang="ts">
import { ref, reactive, nextTick, watch } from 'vue'
import { useEventListener } from '@vueuse/core'
import { useTabStore } from '@/store'
import { setTabRoutes } from '@/utils'
import { ChromeTab, ContextMenu } from './index'

interface Emits {
    (e: 'scroll', clientX: number): void
}

const emit = defineEmits<Emits>()
const tab = useTabStore()

// 获取当前激活的tab的clientX
const tabRef = ref<HTMLElement>()
async function getActiveTabClientX() {
    await nextTick()
    if (tabRef.value) {
        const activeTabElement = tabRef.value.children[tab.activeTabIndex]
        const { x, width } = activeTabElement.getBoundingClientRect()
        const clientX = x + width / 2
        setTimeout(() => {
            emit('scroll', clientX)
        }, 50)
    }
}

const dropdown = reactive({
    visible: false,
    x: 0,
    y: 0,
    currentPath: '',
})
function showDropdown() {
    dropdown.visible = true
}
function hideDropdown() {
    dropdown.visible = false
}
function setDropdown(x: number, y: number, currentPath: string) {
    Object.assign(dropdown, { x, y, currentPath })
}

/** 点击右键菜单 */
async function handleContextMenu(e: MouseEvent, path: string) {
    e.preventDefault()
    const { clientX, clientY } = e
    hideDropdown()
    setDropdown(clientX, clientY, path)
    await nextTick()
    showDropdown()
}

watch(
    () => tab.activeTabIndex,
    () => {
        getActiveTabClientX()
    },
    {
        immediate: true,
    }
)

/** 页面离开时缓存多页签数据 */
useEventListener(window, 'beforeunload', () => {
    setTabRoutes(tab.tabs)
})
</script>
<style scoped>
.mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
</style>
