/**
 * localstorage 封装
 */
import { Encrypt, Decrypt } from '../crypto'

interface StorageData {
    value: unknown
    time: number
}

class LocalStorageAPI {
    set(key: string, value: unknown, isEncrypt: boolean = true) {
		if(isEncrypt) {
			localStorage.setItem(key, Encrypt(JSON.stringify(value)))
		} else {
			localStorage.setItem(key, JSON.stringify(value))
		}
    }

    get(key: string, isEncrypt: boolean = true) {
        const json = localStorage.getItem(key)
        if (!json) return null
		if (!isEncrypt) return json
		let data
        try {
            data = JSON.parse(Decrypt(json))
        } catch (e) {
            // 防止解析失败
        }
        return data
    }

    remove(key: string) {
        localStorage.removeItem(key)
    }

    // 有时效的 localStorage
    /** 默认缓存期限为7天 */
    setExpire(key: string, value: unknown, expire: number = 60 * 60 * 24 * 7) {
        const curTime = new Date().getTime()
        const data: StorageData = { value, time: curTime + expire * 1000 }
        return this.set(key, data)
    }

    getExpire(key: string) {
        if (!this.get(key)) return null
        const { value, time } = this.get(key) as StorageData
        if (new Date().getTime() - time < 0) {
            return value
        } else {
            this.remove(key)
            return null
        }
    }
}
const LocalStorage = new LocalStorageAPI()

export { LocalStorage }
