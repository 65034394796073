import type { Router, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useRouteStore, useAuthStore } from '@/store'

/**
 * 动态路由
 */
export async function createDynamicRouteGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    router: Router
) {
    const routeStore = useRouteStore()
    const authStore = useAuthStore()

    // 初始化动态路由
    if (!routeStore.isAddedDynamicRoute) {
        // 未登录情况下直接回到登录页，登录成功后再加载动态路由
        // if (!authStore.isLogin) {
        //     if (to.name === 'Login') {
        //         next()
        //     } else {
        //         const redirect = to.fullPath
        //         next({ name: 'Login', query: { redirect } })
        //     }
        //     return false
        // }
        await routeStore.initDynamicRoute(router)

        // 动态路由没有加载导致被service-error路由捕获，等待动态路由加载好了，回到之前的路由
        if (to.name === 'service-error') {
            next({ path: to.fullPath, replace: true, query: to.query })
            return false
        }
        // 动态路由已经加载，仍然未找到，重定向到404
        if (to.name === 'service-error') {
            next({ path: '/404', replace: true })
            return false
        }
    }

    return true
}
