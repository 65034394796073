export default [
    // {
    //     path: '/',
    //     redirect: '/login',
    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: 'Login',
    //     meta: {
    //         title: '登录页',
    //     },
    // },
    {
        path: '/',
        redirect: '/dishes/info',
    },
    {
        path: '/404',
        component: 'Error',
        meta: {
            title: '服务器错误',
        },
    },
    {
        path: '/wisdom',
        name: 'Wisdom',
        component: 'Wisdom',
        meta: {
            title: '取餐-智慧大屏',
        },
    },
    {
        path: '/shop',
        name: 'Shop',
        component: 'Shop',
        meta: {
            title: '取物-智慧大屏',
        },
    },
    {
        path: '/entertain',
        name: 'Entertain',
        component: 'Entertain',
        meta: {
            title: '招待餐-智慧大屏',
        },
    },
    {
        name: 'service-error',
        path: '/:pathMatch(.*)*',
        component: 'Error',
        meta: {
            title: '服务器错误',
        },
    },
] as AuthRoute.Route[]
