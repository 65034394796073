import { EnumStorageKey } from '@/types/enum/storage'
import { LocalStorage } from '@/utils'
import {getCookie} from '@/utils'

/** 设置token */
export function setToken(token: string) {
    LocalStorage.set(EnumStorageKey.token, token)
}

/** 获取token */
export function getToken() {
    return LocalStorage.get(EnumStorageKey.token, false) || ''
}

/** 去除token */
export function removeToken() {
    LocalStorage.remove(EnumStorageKey.token)
}

/** 获取refresh token */
export function getRefreshToken() {
    return LocalStorage.get(EnumStorageKey['refresh-token']) || ''
}

/** 设置refresh token */
export function setRefreshToken(token: string) {
    LocalStorage.set(EnumStorageKey['refresh-token'], token)
}

/** 去除refresh token */
export function removeRefreshToken() {
    LocalStorage.remove(EnumStorageKey['refresh-token'])
}

/** 设置用户信息 */
export function getUserInfo() {
    const emptyInfo: Auth.UserInfo = {
        userId: '',
        userName: '',
        userPhone: '',
        permissions: ['visitor'],
    }
    const userInfo: Auth.UserInfo = LocalStorage.get(EnumStorageKey['user-info']) || emptyInfo
    return userInfo
}

/** 获取用户信息 */
export function setUserInfo(userInfo: Auth.UserInfo) {
    LocalStorage.set(EnumStorageKey['user-info'], userInfo)
}

/** 去除用户信息 */
export function removeUserInfo() {
    LocalStorage.remove(EnumStorageKey['user-info'])
}

/** 去除用户相关缓存 */
export function clearAuthStorage() {
    removeToken()
    removeRefreshToken()
    removeUserInfo()
}
//获取权限button
export function getButtonAuth(Btn_handler:any){
    if(getCookie('HrmApiCookie')){
        let button:any = localStorage.getItem('userButton')
        let userButton = JSON.parse(button)
        return userButton.some((item:any) => item.Btn_handler == Btn_handler)
    }else{
        return  true
    }

}

