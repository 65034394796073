<template>
    <el-breadcrumb separator="/">
        <transition-group name="fade-right">
            <el-breadcrumb-item
                v-for="item in breadcrumbs"
                :key="item.name"
                :to="{ path: item.path }"
            >{{item.title}}</el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getBreadcrumbByRouteKey } from '@/utils'

const route = useRoute()
const router = useRouter()
const breadcrumbs = computed(() =>
    getBreadcrumbByRouteKey(route.path as string, router.getRoutes())
)
</script>
<style scoped></style>