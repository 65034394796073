<template>
    <div
        class="fh-100 animation"
        :style="{
			backgroundColor: backgroundColor,
			width: !menuStore.isCollapse ? '64px' : '200px'
		}"
    >
        <el-menu
            router
            unique-opened
            :background-color="backgroundColor"
            :text-color="textColor"
            :active-text-color="activeTextColor"
            :default-active="$route.path"
            :collapse="!menuStore.isCollapse"
        >
            <MenuSub :menus="menus" />
        </el-menu>
    </div>
</template>
<script lang="ts" setup>
import { useMenuStore } from '@/store'
import { MenuSub } from '../index'

const menuStore = useMenuStore()
interface Props {
    backgroundColor?: string
    textColor?: string
    activeTextColor?: string
    menus?: Menu.GlobalMenuOption[]
}
withDefaults(defineProps<Props>(), {
    backgroundColor: '#000',
    textColor: '#f1f1f1',
    activeTextColor: '#409eff',
    menus() {
        return []
    },
})
</script>
<style scoped>
.el-menu {
    border-right: none;
}
</style>
