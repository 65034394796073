/**
 * 拦截器：ContentType 类型数据解析
 *
 * @type {Array}
 */
import { isArray } from 'lodash-es'
import qs from 'qs'

async function transformFile(file: File[] | File, key: string) {
    const formData = new FormData()
    if (isArray(file)) {
        await Promise.all(
            (file as File[]).map((item) => {
                formData.append(key, item)
                return true
            })
        )
    } else {
        await formData.append(key, file)
    }
    return formData
}

enum ContentType {
    json = 'application/json',
    formUrlencoded = 'application/x-www-form-urlencoded',
    formData = 'multipart/form-data',
}

const resultTypeInterceptor = [
    async function (axiosConfig: any) {
        // application/json类型不处理
        let Config = { ...axiosConfig }
        // form类型转换
        if (axiosConfig.headers['Content-Type'] === ContentType.formUrlencoded) {
            Config.data = qs.stringify(Config.data)
        }
        // form-data类型转换
        if (axiosConfig.headers['Content-Type'] === ContentType.formData) {
            const key = Object.keys(Config.data)[0]
            const file = Config.data[key]
            Config.data = await transformFile(file, key)
        }
        return Config
    },
    function (error: any) {
        throw error
    },
]

export default resultTypeInterceptor
