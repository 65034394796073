export const feedBack = () => import('@/views/appraise/feedBack.vue');
export const hostMeal = () => import('@/views/appraise/hostMeal.vue');
export const Lunch = () => import('@/views/appraise/Lunch.vue');
export const Shopping = () => import('@/views/appraise/Shopping.vue');
export const BList = () => import('@/views/business/BList.vue');
export const detailBill = () => import('@/views/business/detailBill.vue');
export const Detailed = () => import('@/views/business/Detailed.vue');
export const Log = () => import('@/views/business/Log.vue');
export const Subsidy = () => import('@/views/business/Subsidy.vue');
export const datas = () => import('@/views/dataAnalysis/datas.vue');
export const statistic = () => import('@/views/dataAnalysis/statistic.vue');
export const diningInfo = () => import('@/views/dining-situation/diningInfo.vue');
export const Info = () => import('@/views/dishes/Info.vue');
export const InfoRelease = () => import('@/views/dishes/InfoRelease.vue');
export const ReleaseAuto = () => import('@/views/dishes/ReleaseAuto.vue');
export const ServedDishes = () => import('@/views/dishes/ServedDishes.vue');
export const G2Plot = () => import('@/views/document/G2Plot.vue');
export const Vite = () => import('@/views/document/Vite.vue');
export const VMarkdown = () => import('@/views/document/VMarkdown.vue');
export const Vue = () => import('@/views/document/Vue.vue');
export const VueNew = () => import('@/views/document/VueNew.vue');
export const vueuse = () => import('@/views/document/vueuse.vue');
export const Error = () => import('@/views/error/Error.vue');
export const FList = () => import('@/views/forum/FList.vue');
export const About = () => import('@/views/home/about/About.vue');
export const Home = () => import('@/views/home/Home.vue');
export const Lottie = () => import('@/views/home/lottie/Lottie.vue');
export const Markdown = () => import('@/views/home/markdown/Markdown.vue');
export const Table = () => import('@/views/home/table/Table.vue');
export const Login = () => import('@/views/login/Login.vue');
export const NList = () => import('@/views/notice/NList.vue');
export const OList = () => import('@/views/order/OList.vue');
export const index = () => import('@/views/partition/list/index.vue');
export const analysis = () => import('@/views/primecost/analysis.vue');
export const cblist = () => import('@/views/primecost/cblist.vue');
export const property = () => import('@/views/primecost/property.vue');
export const Class = () => import('@/views/smallBank/Class.vue');
export const detailForm = () => import('@/views/smallBank/detailForm.vue');
export const List = () => import('@/views/smallBank/List.vue');
export const Sale = () => import('@/views/smallBank/Sale.vue');
export const Sale1 = () => import('@/views/smallBank/Sale1.vue');
export const Classify = () => import('@/views/supermarket/Classify.vue');
export const SList = () => import('@/views/supermarket/SList.vue');
export const Entertain = () => import('@/views/wisdom/Entertain.vue');
export const Shop = () => import('@/views/wisdom/Shop.vue');
export const Wisdom = () => import('@/views/wisdom/Wisdom.vue');

export default {
  feedBack,
  hostMeal,
  Lunch,
  Shopping,
  BList,
  detailBill,
  Detailed,
  Log,
  Subsidy,
  datas,
  statistic,
  diningInfo,
  Info,
  InfoRelease,
  ReleaseAuto,
  ServedDishes,
  G2Plot,
  Vite,
  VMarkdown,
  Vue,
  VueNew,
  vueuse,
  Error,
  FList,
  About,
  Home,
  Lottie,
  Markdown,
  Table,
  Login,
  NList,
  OList,
  index,
  analysis,
  cblist,
  property,
  Class,
  detailForm,
  List,
  Sale,
  Sale1,
  Classify,
  SList,
  Entertain,
  Shop,
  Wisdom,
}