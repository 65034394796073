import type { RouteRecordRaw } from 'vue-router'

/**
 * 获取面包屑数据
 * @param path - 当前页面路由
 * @param menus - 路由表数据
 */
export function getBreadcrumbByRouteKey(path: string, menus: RouteRecordRaw[]) {
    const breadCrumbMenu: Menu.GlobalBreadcrumb[] = []
    const nameArray: string[] = path
        .split('/')
        .filter(i => i)
        .map(i => firstUpperCase(i))
    for (let name of nameArray) {
        for (let item of menus) {
            if (item.name === name) {
                breadCrumbMenu.push({
                    name: item.name as string,
                    path: item.path,
                    title: item.meta?.title as string,
                })
            }
        }
    }
    return breadCrumbMenu
}
/**
 * 单词字母大写
 * @param str - 字母数据
 */
export function firstUpperCase(str: string) {
    return str.toLowerCase().replace(/( |^|_)[a-z]/g, (L) => L.toUpperCase())
}
