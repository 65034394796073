<template>
    <div
        class="relative flex a-center h-34 pl-24 pr-24 cursor-pointer"
        :class="{ 'z-10': isActive, 'z-9': isHover }"
        style="margin-left: -18px;"
        @mouseenter="setTrue"
        @mouseleave="setFalse"
    >
        <div
            class="absolute fw-100 fh-100 hidden"
            style="left:0;bottom:0;"
        >
            <ChromeBackground
                class="fw-100 fh-100"
                :is-active="isActive"
                :is-hover="isHover"
                :dark-mode="darkMode"
                :primary-color="primaryColor"
            />
        </div>
        <span class="relative whitespace-nowrap z-2">
            <slot></slot>
        </span>
        <div
            v-if="closable"
            class="pl-18 z-1 flex center"
            @mouseenter="setIconTrue"
            @mouseleave="setIconFalse"
        >
            <el-icon
                :color="isActive?'#409eff':'#a7a7a7'"
                @click="handleClose"
            >
                <close-bold v-if="!isIconHover" />
                <circle-close-filled v-else />
            </el-icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { CloseBold, CircleCloseFilled } from '@element-plus/icons-vue'
import { useBoolean } from '@/hooks'
import { ChromeBackground } from './index'

interface Props {
    /** 激活状态 */
    isActive?: boolean
    /** 主题颜色 */
    primaryColor?: string
    /** 是否显示关闭图标 */
    closable?: boolean
    /** 暗黑模式 */
    darkMode?: boolean
}

interface Emits {
    /** 点击关闭图标 */
    (e: 'close'): void
}

withDefaults(defineProps<Props>(), {
    isActive: false,
    primaryColor: '#409EFF',
    closable: true,
    darkMode: false,
    isLast: false,
})

const emit = defineEmits<Emits>()

const { bool: isHover, setTrue, setFalse } = useBoolean()
const { bool: isIconHover, setTrue: setIconTrue, setFalse: setIconFalse } = useBoolean()

function handleClose(e: MouseEvent) {
    e.stopPropagation()
    emit('close')
}
</script>
<style scoped></style>
