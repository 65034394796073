<template>
    <div class="flex a-center fw-100 h-34 bg-white">
        <div
            ref="bsWrapper"
            class="flex-1 hidden fh-100 pl-20"
        >
            <better-scroll
                ref="bsScroll"
                :options="{ scrollX: true, scrollY: false, click: false }"
            >
                <tag-detail @scroll="handleScroll" />
            </better-scroll>
        </div>
        <el-icon
            class="w-50 cursor-pointer app-refresh"
            @click="app.reloadPage"
        >
            <refresh />
        </el-icon>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useElementBounding } from '@vueuse/core'
import { Refresh } from '@element-plus/icons-vue'
import { useAppStore, useTabStore } from '@/store'
import { BetterScroll, TagDetail } from './index'

const route = useRoute()
const app = useAppStore()
const tab = useTabStore()

const bsWrapper = ref<HTMLElement>()
const { width: bsWrapperWidth, left: bsWrapperLeft } = useElementBounding(bsWrapper)

const bsScroll = ref<Menu.ExposeBetterScroll>()

function handleScroll(clientX: number) {
    const currentX = clientX - bsWrapperLeft.value
    const deltaX = currentX - bsWrapperWidth.value / 2
    if (bsScroll.value) {
        const { maxScrollX, x: leftX } = bsScroll.value.instance
        const rightX = maxScrollX - leftX
        const update = deltaX > 0 ? Math.max(-deltaX, rightX) : Math.min(-deltaX, -leftX)
        bsScroll.value?.instance.scrollBy(update, 0, 300)
    }
}

function init() {
    tab.iniTabStore(route)
}

watch(
    () => route.path,
    () => {
        tab.addTab(route)
        tab.setActiveTab(route.path)
    }
)

// 初始化
init()
</script>
<style scoped>
.app-refresh {
    color: #999;
}
.app-refresh:hover {
    color: #333;
}
</style>
