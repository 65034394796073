<template>
    <div
        class="dropdown fixed flex column"
        :style="{left: `${props.x}px`,top: `${props.y}px`}"
    >
        <div
            class="dropdown-item cursor-pointer"
            v-for="item in options"
            :key="item.key"
            @click="handleDropdown(item.key)"
        >{{item.label}}</div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore, useTabStore } from '@/store'

interface Props {
    /** 右键菜单可见性 */
    visible?: boolean
    /** 当前路径 */
    currentPath: string
    /** 鼠标x坐标 */
    x: number
    /** 鼠标y坐标 */
    y: number
}
interface Emits {
    (e: 'update:visible', visible: boolean): void
}

const props = withDefaults(defineProps<Props>(), {
    visible: false,
    currentPath: '',
})
const emit = defineEmits<Emits>()
const dropdownVisible = computed({
    get() {
        return props.visible
    },
    set(visible: boolean) {
        emit('update:visible', visible)
    },
})

const app = useAppStore()
const tab = useTabStore()

const options = computed(() => [
    {
        label: '重新加载',
        key: 'reload-current',
    },
    {
        label: '关闭',
        key: 'close-current',
    },
    {
        label: '关闭其他',
        key: 'close-other',
    },
    {
        label: '关闭左侧',
        key: 'close-left',
    },
    {
        label: '关闭右侧',
        key: 'close-right',
    },
])

const actionMap = new Map([
    [
        'reload-current',
        () => {
            app.reloadPage()
        },
    ],
    [
        'close-current',
        () => {
            tab.removeTab(props.currentPath)
        },
    ],
    [
        'close-other',
        () => {
            tab.clearTab([props.currentPath])
        },
    ],
    [
        'close-left',
        () => {
            tab.clearLeftTab(props.currentPath)
        },
    ],
    [
        'close-right',
        () => {
            tab.clearRightTab(props.currentPath)
        },
    ],
])

function handleDropdown(optionKey: string) {
    const actionFunc = actionMap.get(optionKey)
    if (actionFunc) {
        actionFunc()
    }
    emit('update:visible', false)
}
</script>
<style scoped>
.dropdown {
    background: #fff;
    color: #666;
    font-size: 14px;
    border-radius: 2px;
    padding: 4px 0;
    box-shadow: 0 0 6px 0px rgb(0 0 0 / 10%);
    z-index: 11;
}
.dropdown-item {
    padding: 4px 10px;
}
.dropdown-item:hover {
    background: #e8f0ff;
}
</style>
